import './App.css';
import data from './data/data.json';
import React from "react";



  const germanWordslength = data.length
  
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  let randomInt = getRandomInt(germanWordslength)
  console.log(randomInt)

  

function App() {
  const [showResult, setShowResult] = React.useState(false);
  const [score, setScore] = React.useState(0);
  const [rightAnswer, setrightAnswer] = React.useState();
  const [answerGiven, setAnswerGiven] = React.useState(false);
  const [totalAnswersGiven, setTotalAnswersGiven] = React.useState(1)

  function nextQuestion(){
    randomInt = getRandomInt(germanWordslength)
    console.log(randomInt)
    setShowResult(false)
    setAnswerGiven(false)
    setTotalAnswersGiven(totalAnswersGiven+1)
    setrightAnswer(false)
  }



  function questionResult(e){
    //console.log(data[randomInt].german.artikel)
    if(e===data[randomInt].German.Artikel){
      if(answerGiven===false){
        console.log(e + " is the Correct Answer!")
        setShowResult(true)
        setScore(score+1)
        setrightAnswer(true)
        setAnswerGiven(true)
      }
    }else{
      if(answerGiven===true){
        setShowResult(true)
        setAnswerGiven(true)
      }else{
        setrightAnswer(false)
        setShowResult(true)
        setAnswerGiven(true)
      }

    }


  }

  /*const percentage = score/(totalAnswersGiven)*100*/
  /*({percentage.toFixed(0)}%)*/

  return (
    <div className="container">
      <div>
        <h1>German Article Exercise</h1>
      </div>
      <div>
        <h2>Score: {score} </h2>
        <h3>Question: {totalAnswersGiven}</h3>
      </div>
      <div>
        <h3>{rightAnswer ? <span style={{ color: 'green'}}>Right Answer!</span> : answerGiven ? <span style={{ color: 'red'}}>Wrong Answer!</span> : <p> </p>}</h3>
      </div>
      <br/>
      <div>
       {showResult ? <span>{data[randomInt].German.Artikel}</span> : <span>...</span>} {data[randomInt].German.Wort} ({data[randomInt].english})
       </div>
       <br/>
       <br/>
       <button value="Das" onClick={e => questionResult(e.target.value)}>DAS</button>
       <br/>
       <br/>
       <button value="Der" onClick={e => questionResult(e.target.value)}>DER</button>
       <br/>
       <br/>
       <button value="Die" onClick={e => questionResult(e.target.value)}>DIE</button>
       <br/>
       <br/>
       {showResult ? <span><button onClick={nextQuestion}>Next</button></span> : <span></span>}

    </div>
  );
}

export default App;
